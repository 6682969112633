import { Typography, Box, Skeleton, useMediaQuery, Unstable_Grid2 as Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { BASE_API_URL } from '@/constants'
import { useState, useEffect } from 'react'
import gitHubIcon from '@/images/github-small-80.webp'
import linkedInIcon from '@/images/linkedin-small-80.webp'
import Lottie from 'react-lottie-player'
import logo from '@/images/logo.svg'
import logoLottie from '@/images/logo-1.2.json'
import '@/components/footer/footer.scss'

export const Footer = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
    const [intro, setIntro] = useState()

    const fetchData = () => {
        const url = BASE_API_URL + 'portfolio-intro'
        const options = { method: "GET" }

        fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                setIntro(data[0])
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const expandSx = {
        color: '#aaa',

        '&:hover': {
             color: '#fff',
        },
    }

    const socials = <>
        <Link to="https://www.linkedin.com/in/hangar18studio/" target="_blank">
            <img src={linkedInIcon} width="30" height="30" alt="LinkedIn logo" className="linkedin" />
        </Link>
        <Link to="https://github.com/gbennettB2S" target="_blank">
            <img src={gitHubIcon} width="30"  height="30" alt="GitHub logo" className="github" />
        </Link>
    </>

    return (
    <>        
        <Grid className="footer-jargon" container justifyContent="space-between">
            <Grid xs={10} lg={10}>
                <Box className="socials">
                    {socials}

                    {intro ?
                        <Link to={intro.resume_pdf.guid} target="_blank" className="resume" sx={{fontWeight: "bold"}}>Resume</Link>
                        :
                        <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '2rem', ml: -0.5, mb: 2 }} />
                    }
                </Box>
                <Box className="skills-tech">
                    Jargon ·&nbsp;
                    {intro ? intro.skills_and_tech.map((item, index) => (
                            <Typography className="skills-row" variant="body1" dangerouslySetInnerHTML={{ __html: item }} key={index}></Typography>
                        ))
                        :
                        <Skeleton variant="rounded" width={420} height={162} sx={{ ml: 4.3 }} />
                    }
                </Box>
            </Grid>

            <Grid xs={2} lg={2} sx={{ textAlign: 'center' }}>
                {/* <img src={logo} width="130"  height="130" alt="Gabe Bennett logo" className="logo" /> */}
                <Lottie
                    loop
                    animationData={logoLottie}
                    play
                    className="logo"
                />
            </Grid>
        </Grid>
    </>
    )
}
