import { Typography, Box, Skeleton, useMediaQuery, Unstable_Grid2 as Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { BASE_API_URL } from '@/constants'
import { useState, useEffect } from 'react'
import '@/components/introSidebar/introSidebar.scss'

export const IntroSidebar = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
    const [intro, setIntro] = useState()
    const [isBioExpanded, setIsBioExpanded] = useState(false)

    const fetchData = () => {
        const url = BASE_API_URL + 'portfolio-intro'
        const options = { method: "GET" }

        fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                setIntro(data[0])
            })
    }

    useEffect(() => {
        fetchData()
    }, [])


    const expandSx = {
        color: '#aaa',

        '&:hover': {
             color: '#fff',
        },
    }

    return (
    <>
        <Grid container>
            <Grid xs={2.3} sm={2.5} md={2} lg={1.1}>
            {intro ?
                <img
                    src={intro.better_featured_image.media_details.sizes.thumbnail.source_url}
                    alt={intro.better_featured_image.alt_text}
                    width={intro.better_featured_image.media_details.sizes.thumbnail.width}
                    height={intro.better_featured_image.media_details.sizes.thumbnail.height}
                    className={`my-portrait`}
                />
                :
                <Skeleton variant="circular" className="my-portrait" width={340} height={340} sx={{ mt: 2.5 }} />
            }
            </Grid>
            <Grid xs={9.7} sm={6.5} md={5} lg={3.9} className="title-block">
                <Typography variant="h1" sx={{ ml: -0.5 }} className="my-name">
                    {intro
                        ? intro.title.rendered
                        : <Skeleton variant="text" className="my-name" sx={{ fontSize: '4rem', width: '18rem' }} />
                    }
                </Typography>
                <Typography variant="h2" className="my-title">
                    {intro
                        ? intro.subhead
                        : <Skeleton variant="text" className="my-title"
                            sx={{ fontSize: '2rem', width: '17rem', ml: -0.5, mb: 1 }} />
                    }
                </Typography>
            </Grid>
            <Grid xs={3} sm={3} md={5} lg={7} className="bio-block">
                <>
                    {intro ?
                        <Typography className="bio-text" variant="body2">
                                {intro.bio}
                        </Typography>
                        :
                        <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '2rem', ml: -0.5, mb: 2 }} />
                    }
                </>
            </Grid>
        </Grid>
    </>
    )
}
